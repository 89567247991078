@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/variables';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/tools/mixins';
@import '../../wdx/mixins/typography';

.cardFlex {
  display: flex;
  flex-direction: row-reverse;
  padding: ($ingredients-unit * 4) ($ingredients-unit * 4) ($ingredients-unit * 3);

  @include media-breakpoint-up('sm') {
    flex-direction: row;
    padding: ($ingredients-unit * 4) ($ingredients-unit * 4) 0;
  }
}

.noPadding>div {
  padding: 0;
}

.clockIcon {
  color: $colour-chia-grey;
  margin-left: -$ingredients-gutter * 0.25;
}

.summary {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;

  dt {
    @include paragraph-sub('medium');
    margin: 0;
    white-space: nowrap;

    &:not(:first-child) {
      margin-top: $ingredients-gutter;
    }
  }

  dd {
    @include paragraph;
  }

  @include media-breakpoint-up('sm') {
    display: grid;
    grid-template-columns: auto 3fr 1fr;
    grid-template-rows: min-content min-content;
    column-gap: $ingredients-gutter;
    grid-row-gap: 0;
    margin-left: 20px;

    dt {
      grid-row: 1 / 2;
      margin: 0;
      white-space: nowrap;

      &:not(:first-child) {
        margin-top: 0;
      }
    }

    dd {
      grid-row: 2 / 3;
    }

    .orderTotals {
      margin-left: 20%;
    }
  }

  .deliveryAddress {
    @include media-breakpoint-down('xs') {
      display: none;
    }
  }
}

.statusAndMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ($ingredients-unit * 4);
  padding: ($ingredients-unit * 3) ($ingredients-unit * 4) ($ingredients-unit * 4);

  border-top: 1px solid $colour-mushroom-grey;

  @include media-breakpoint-up('sm') {
    flex-direction: row;
    align-items: center;
    padding: ($ingredients-unit * 5);
  }

  .footerButtons {
    display: inline-flex;
    height: min-content;
    gap: 16px;
    flex-wrap: wrap;


    @include media-breakpoint-up('sm') {
      flex-wrap: nowrap;
    }

    & > * {
      flex: 1 1 100%;
      @include media-breakpoint-up('sm') {
        flex: none;
      }
    }
  }

  .statusText {
    flex: 1 0 max-content;
    display: inline-flex;

    p {
      @include paragraph-sub;
      margin: 0;
      display: inline;
    }

    .statusWithIcon {
      display: flex;

      @include media-breakpoint-up('md') {
        display: inline-flex;
      }

      @mixin icon {
        flex-shrink: 0;
        margin-top: 6px;
      }

      .errorIcon {
        @include icon;

        color: $colour-error-red;
      }

      .warningIcon {
        @include icon;

        color: $colour-warning;
      }

      p {
        margin-left: $ingredients-gutter * 0.5;
        margin-top: $ingredients-unit;
      }
    }
  }


  .amendingStatusText {
    display: flex;
    flex-direction: column;
    color: $colour-white;
    padding: $ingredients-gutter * 0.5 $ingredients-gutter;
    width: 100%;
    gap: $ingredients-unit * 2;

    @include media-breakpoint-up('sm') {
      flex-direction: row;
      gap: $ingredients-unit * 9;
      padding: 0;

      .amendingMessageSeparator {
        display: none;
      }

      p {
        padding: 10px;
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: $ingredients-unit * 4;
      padding-bottom: $ingredients-unit * 2;
      align-items: baseline;

      @include media-breakpoint-up('sm') {
        justify-content: flex-end;
        margin: 0;
        padding-bottom: 0;
        gap: $ingredients-unit * 6;
      }
    }

    .viewOrderLink {
      @include paragraph('medium');
      color: $colour-white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-decoration: underline;
      white-space: nowrap;
    }

    @include media-breakpoint-up('sm') {
      display: flex;
      justify-content: space-between;

      h4 {
        @include paragraph;
        line-height: 1.5;
        margin: $ingredients-gutter * 0.5;
      }
    }
  }
}

.darkFooter {
  background-color: $colour-primary-grey;
}