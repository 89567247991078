@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../styles/settings/variables';
@import '../../../styles/tools/mixins';


.orderCard > div {
    gap: 14px;

    &> div:nth-child(2) {
        margin: 0;
    }
}

.orderCardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $ingredients-unit * 3;
}

.topRow {
    display: flex;
}

.bottomRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: $ingredients-unit * 3;


    @include media-breakpoint-up('lg') {
        flex-direction: row;
    }
}

.deliveryTracker {
    display: inline-flex;
    flex: 1;
    justify-content: center;


    @include media-breakpoint-up('lg') {
        min-width: 530px;
        max-width: 530px;
    }
}

.deliveryTrackerContainer {
    width: 100%;
    display: inline-flex;
    flex: 1;
    justify-content: center;


    @include media-breakpoint-up('lg') {
        justify-content: flex-end;
    }
}

.viewOrderPage {
    .bottomRow {
        flex-direction: column;
    }

    .deliveryTracker {
        min-width: 100%;
        max-width: 100%;
    }

    .deliveryTrackerContainer {
        justify-content: center;
    }

}

.paymentFailedCard {
    border: solid 1px $colour-error-red;
}