@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';


.orderDetails {
    display: flex;
    flex-direction: column;
    max-width: max-content;
    min-width: min-content;
    gap: $ingredients-unit * 0.5;
}

.orderDetailsDateTime {
    font-size: 22px;
    color: $colour-primary-grey;
}

.addressAndTotals {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style-type: none;
    align-items: center;
    gap: 0 $ingredients-unit * 4;

    &>li:not(:last-child) {

        @include media-breakpoint-up('sm') {
            padding-right: $ingredients-unit * 4;
            border-right: 1px solid $colour-oyster-grey;
        }
    }
}