@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';

.orderStatus {
    display: flex;
    gap: $ingredients-unit * 2;
    align-items: center;
}

.pendingIcon,
.vanIcon,
.cancelledIcon,
.missedIcon {
    color: $colour-chia-grey;
}

.warningIcon {
  color: $colour-warning;
}

.errorIcon {
  color: $colour-error-red;
}

.completedIcon {
    color: $colour-success-green;
}

.amendingIcon {
    color: $colour-corporate-lime-green;
}

.orderStatusLabel {
    color: $colour-earl-grey;
    font-size: 12px;
    line-height: 20px;
}